import React, { useEffect, useState, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  Button,
  ButtonBar,
  InputFile,
  List,
  StaticAssetActions,
  Message,
  SelectField,
  LabelledInput,
} from '@tg/core/components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { handleTimeZone } from '@tg/core/utils/datetimeHelpers';
import { useResource } from '@tg/core/hooks';
import { capitalise } from '@tg/core/utils/stringHelpers';
import { isStartDateAfterFirstJan } from '@tg/core/utils/holidayYearHelper';
import formValidation from './formValidation';
import ErrorModal from '@tg/core/components/molecules/ErrorModal';

const NewBooking = ({
  year,
  isOpen,
  onClose,
  existingEvent,
  event_types,
  onSuccess,
  contractId,
  getCalendarData,
  startDate,
  endDate,
  calendarYearsData,
}) => {
  const { t } = useTranslation(['time_off', 'forms']);
  const { ROOT_API } = process.env;
  const token = useSelector(s => s.session.aut);

  const [formDataYear, setFormData] = useState(null);
  const [error, setError] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);

  const [maxYear, setMaxYear] = useState(null);
  const [calendarYearsWithData, setCalendarYearsWithData] = useState([]);
  const [setPlannerYear, setYear] = useState(null);

  useEffect(() => {
    if (calendarYearsData?.length) {
      setCalendarYearsWithData(calendarYearsData);
      const maxYr = Math.max(
        ...calendarYearsData.map(singleYearData => singleYearData.year),
      );
      const maxYearData = calendarYearsData.find(
        calYear => calYear.year === maxYr,
      );
      setMaxYear(maxYr);
      setEndYear(maxYearData.year);
    }
  }, [calendarYearsData]);

  useEffect(() => {
    document.addEventListener('click', e => {
      if (e.target.classList.contains('react-datepicker__day--disabled')) {
        setShowPopup(true);
      }
    });
  }, []);

  const { deleteResource, isFetching: editLoading } = useResource(
    {
      url: `contracts/${contractId}/planner/${setPlannerYear}/${
        existingEvent && existingEvent.id
      }`,
    },
    false,
  );

  const { deleteResource: deleteAttachment } = useResource(
    {
      url: `contracts/${contractId}/planner/${setPlannerYear}/${
        existingEvent && existingEvent.id
      }`,
    },
    false,
  );

  useEffect(() => {
    setError(null);
  }, []);

  const newEventDefaults = useMemo(
    () => ({
      start_date: '',
      first_day_half: false,
      end_date: '',
      last_day_half: false,
      take_unpaid: false,
      leaveType: '',
    }),
    [],
  );
  const { getFieldProps, handleSubmit, control, watch } = Form.useForm({
    validationSchema: formValidation,
  });

  const [filesToShow, setFilesToShow] = useState([]);
  const [defaultValues, setDefaultValues] = useState(newEventDefaults);
  const [employeeFiles, setEmployeeFiles] = useState([]);
  const [validationMessage, setValidationMessage] = useState(null);
  const [leaveType, setLeaveType] = useState(null);
  const [isValidFormat, setIsValidFormat] = useState({
    message: null,
    isValid: true,
  });
  const dateRangeReducer = (state, { start = null, end = null }) => {
    return {
      start: start ? new Date(start) : start,
      end: end ? new Date(end) : end,
    };
  };
  const [selectedRange, dispatchSelectedRange] = useReducer(dateRangeReducer, {
    start: null,
    end: null,
  });
  // Set up for 'range' UI on the date picker so the user can't pick a end date before the start
  // The date picker emits dates as strings but want's these props back as Date objects.
  const watchStartDate = watch('new_time_off.start_date', '');
  const watchEndDate = watch('new_time_off.end_date', '');

  useEffect(() => {
    dispatchSelectedRange({ start: watchStartDate, end: watchEndDate });
  }, [watchStartDate, watchEndDate]);

  useEffect(() => {
    if (maxYear && Object.keys(startDate)?.length) {
      if (isStartDateAfterFirstJan(startDate)) {
        setEndYear(maxYear + 1);
      }
    }
  }, [maxYear, startDate, endDate]);

  const getPlanner = startDt => {
    let planner;
    calendarYearsWithData.forEach(planr => {
      if (
        handleTimeZone(new Date(startDt)) >=
          handleTimeZone(new Date(planr.start_date)) &&
        handleTimeZone(new Date(startDt)) <=
          handleTimeZone(new Date(planr.end_date))
      ) {
        planner = planr;
      }
    });
    return planner;
  };

  useEffect(() => {
    if (existingEvent) {
      const { start_date, end_date, start_perc, end_perc } = existingEvent;
      const firstDayHalf =
        start_date === end_date ? end_perc === 50 : start_perc === 50;
      const lastDayHalf =
        start_date === end_date ? start_perc === 50 : end_perc === 50;
      setDefaultValues({
        start_date: existingEvent.start_date || '',
        first_day_half: firstDayHalf,
        end_date: existingEvent.end_date || '',
        last_day_half: lastDayHalf,
        take_unpaid: existingEvent.event_type === 'unpaid',
      });
      setLeaveType(existingEvent?.leave_type_id || '');
      const { documents = [] } = existingEvent;
      setFilesToShow(
        documents?.map(doc => {
          return { ...doc, name: doc.filename };
        }),
      );
      const planner = getPlanner(start_date);
      setYear(planner.year);
    } else {
      setDefaultValues(newEventDefaults);
      setLeaveType(null);
      setError(null);
    }
  }, [existingEvent, newEventDefaults]);

  const fileToDataUri = file =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const onEmployeeFileDrop = dropped => {
    setEmployeeFiles([...employeeFiles, ...dropped]);
    const droppedFiles = [...dropped];
    const filesToShowObj = filesToShow?.length ? [...filesToShow] : [];

    droppedFiles.forEach(droppedFile => {
      fileToDataUri(droppedFile).then(convertedBlob => {
        const convertedBlobObj = {
          name: droppedFile.name,
          blob: convertedBlob,
        };
        setFilesToShow([...filesToShowObj, convertedBlobObj]);
      });
    });
  };

  const onClosePopup = () => {
    setEmployeeFiles([]);
    setFilesToShow([]);
    onClose();
    setDateError(null);
    setLeaveType('');
    setError(null);
  };

  const deleteEvent = () => {
    deleteResource({
      onSuccess: () => {
        onClosePopup();
        onSuccess();
      },
    });
  };

  const leaveTypeOptions = event_types?.filter(
    val => val.leave_type !== 'public-holidays',
  );

  const onModalClose = () => {
    onClosePopup();
  };

  const onAPISuccess = () => {
    onClosePopup();
    onSuccess();
  };

  const onError = res => {
    console.log('res', res);
    if (res?.response?.data?.error?.includes('invalid file type')) {
      setIsValidFormat({ message: res?.response?.data?.error, isValid: false });
    } else {
      setError(t('time_off:new_event.errors.date_clash'));
    }
  };

  const callApi = async (fData, yr, reqType) => {
    let url = `${ROOT_API}/contracts/${contractId}/planner/${yr}`;
    if (reqType === 'patch') {
      url = `${ROOT_API}/contracts/${contractId}/planner/${yr}/${
        existingEvent && existingEvent.id
      }`;
    }
    const option = {
      method: reqType,
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: fData,
    };
    try {
      const res = await axios(option);
      console.log(res);
      onAPISuccess();
    } catch (err) {
      onError(err);
    }
  };

  const sendApi = (formsData, yr) => {
    setShowPopup(false);
    setWarningMsg(null);

    const fData = formsData || formDataYear.formDataValues;
    const yrData = yr || setPlannerYear;
    if (existingEvent) {
      callApi(fData, yrData, 'patch');
    } else {
      callApi(fData, yrData, 'post');
    }
  };

  const isEndDateBeforeOrEqual = (planr, enddt) => {
    let retValue = false;
    if (enddt <= planr.end_date) {
      retValue = true;
    }
    return retValue;
  };

  const onSubmit = values => {
    const { start_date, end_date, note, first_day_half, last_day_half } =
      values.new_time_off;
    if (!start_date) {
      setDateError(t('time_off:new_event.errors.start_date_required'));
      return;
    }
    if (start_date && !end_date) {
      setDateError(t('time_off:new_event.errors.end_date_required'));
      return;
    }
    if (end_date === start_date && first_day_half && last_day_half) {
      setError(t('time_off:new_event.errors.no_two_halfs_for_same_day'));
      return;
    }
    setDateError(null);
    setError(null);
    const planner = getPlanner(start_date);
    const totalLeavesAvailable = planner?.leave_available;
    const entitlementUsed = planner?.entitlement_used;
    setYear(planner.year);
    if (leaveType) {
      if (isEndDateBeforeOrEqual(planner, end_date)) {
        const formData = new FormData();
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);

        if (employeeFiles?.length) {
          employeeFiles.forEach(employeeFile => {
            if (employeeFile instanceof File) {
              formData.append('files[]', employeeFile);
            }
          });
        }
        formData.append('note', note);
        formData.append('leave_type_id', leaveType);
        if (end_date && end_date !== start_date) {
          formData.append('start_perc', first_day_half ? 50 : 100);
          formData.append('end_perc', last_day_half ? 50 : 100);
        } else {
          formData.append('start_perc', last_day_half ? 50 : 100);
          formData.append('end_perc', first_day_half ? 50 : 100);
        }

        const leaveTypeName = leaveTypeOptions.find(
          item => item.leave_type_id === leaveType,
        )?.leave_type;

        const startDt = new Date(start_date);
        const endDt = new Date(end_date);
        const diff = endDt.getTime() - startDt.getTime();
        let totalDays = Math.ceil(diff / (1000 * 3600 * 24)) + 1;
        if (first_day_half) {
          totalDays -= 0.5;
        }
        if (last_day_half) {
          totalDays -= 0.5;
        }
        if (
          leaveTypeName === 'holiday' &&
          Number(totalLeavesAvailable[leaveTypeName]) -
            Number(entitlementUsed[leaveTypeName]) <
            totalDays
        ) {
          setFormData({ formDataValues: formData });
          setShowPopup(true);
          setWarningMsg(t('time_off:new_event.errors.more_days_error'));
        } else {
          sendApi(formData, planner.year);
        }
        setValidationMessage(null);
      } else {
        setError(t('time_off:new_event.errors.split_leave_msg'));
      }
    } else {
      setValidationMessage(t('time_off:new_event.errors.leave_type_validate'));
    }
  };

  const onDelete = fileValue => {
    let filesToShowObj = [...filesToShow];
    filesToShowObj = filesToShowObj.filter(
      fileToShow => fileToShow.file_id !== fileValue.file_id,
    );
    let employeeFilesObj = [...employeeFiles];
    employeeFilesObj = employeeFilesObj.filter(
      employeeFile => employeeFile.file_id !== fileValue.file_id,
    );
    deleteAttachment({
      id: fileValue.file_id,
      onSuccess: () => {
        getCalendarData();
        setFilesToShow(filesToShowObj);
        setEmployeeFiles(employeeFilesObj);
      },
    });
  };

  return (
    <>
      <Modal
        isOpen={showPopup}
        title='Warning'
        width='xs'
        onClose={() => {
          setShowPopup(false);
          setWarningMsg(null);
        }}
      >
        <Modal.Content>
          {warningMsg || t('time_off:warning_popup.date_outside_range')}
        </Modal.Content>
        <ButtonBar>
          {warningMsg ? (
            <>
              <div className='mr-2'>
                <Button
                  onClick={() => {
                    setShowPopup(false);
                    setWarningMsg(null);
                  }}
                  color='secondary'
                >
                  No
                </Button>
              </div>
              <Button onClick={() => sendApi()} color='primary'>
                Yes
              </Button>
            </>
          ) : (
            <Button
              onClick={() => {
                setShowPopup(false);
                setWarningMsg(null);
              }}
              color='secondary'
            >
              OK
            </Button>
          )}
        </ButtonBar>
      </Modal>
      <div className={showPopup ? 'invisible' : 'visible'}>
        <Modal
          isOpen={isOpen}
          onClose={onModalClose}
          title={t('time_off:new_request.form_title')}
          width='xs'
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Content>
              {error && (
                <div className='mb-4'>
                  <Message
                    type='error'
                    title={t('time_off:new_event.errors.title')}
                  >
                    {error}
                  </Message>
                </div>
              )}
              {dateError && (
                <div className='mb-4'>
                  <Message type='error'>{dateError}</Message>
                </div>
              )}
              <div className='grid gap-4 grid-cols-2 mb-8'>
                <div>
                  <div className='mb-4'>
                    <Form.DateField
                      {...getFieldProps({
                        id: 'new_time_off',
                        name: 'start_date',
                      })}
                      selectsStart
                      startDate={selectedRange.start}
                      endDate={selectedRange.end}
                      minDate={new Date(year, startDate.month, startDate.date)}
                      maxDate={new Date(endYear, endDate.month, endDate.date)}
                      required
                      defaultValue={defaultValues.start_date}
                      autoComplete='off'
                    />
                  </div>
                  <Form.ToggleField
                    {...getFieldProps({
                      id: 'new_time_off',
                      name: 'first_day_half',
                    })}
                    control={control}
                    value='first_day_half'
                    defaultChecked={defaultValues?.first_day_half}
                  />
                </div>
                <div>
                  <div className='mb-4'>
                    <Form.DateField
                      {...getFieldProps({
                        id: 'new_time_off',
                        name: 'end_date',
                      })}
                      selectsEnd
                      startDate={selectedRange.start}
                      endDate={selectedRange.end}
                      minDate={
                        selectedRange.start
                          ? selectedRange.start
                          : new Date(year, startDate.month, startDate.date)
                      }
                      required
                      maxDate={new Date(endYear, endDate.month, endDate.date)}
                      defaultValue={defaultValues.end_date}
                      autoComplete='off'
                    />
                  </div>
                  <Form.ToggleField
                    {...getFieldProps({
                      id: 'new_time_off',
                      name: 'last_day_half',
                    })}
                    control={control}
                    defaultChecked={defaultValues?.last_day_half}
                  />
                </div>
              </div>
              <LabelledInput id='data-filter' label='Leave-type' required>
                <SelectField
                  {...getFieldProps({
                    id: 'new_time_off',
                    name: 'leave_type_id',
                  })}
                  required
                  onChange={value => {
                    setLeaveType(value);
                  }}
                  value={leaveType}
                  options={leaveTypeOptions?.map(item => ({
                    text: capitalise(item.leave_type),
                    value: item.leave_type_id,
                  }))}
                  style={{ width: '480px' }}
                  placeholder={t('time_off:new_event.placeholders.leave_type')}
                />
              </LabelledInput>
              {validationMessage && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {validationMessage}
                </div>
              )}
              <div style={{ marginTop: '20px' }}>
                <InputFile multiple onDrop={onEmployeeFileDrop} />
              </div>
              {filesToShow?.length ? (
                <List
                  items={filesToShow.map(f => {
                    const columnObj = [<span>{f.name}</span>];
                    if (f.url) {
                      columnObj.push(
                        <StaticAssetActions
                          url={f.url}
                          file={f}
                          type='request_leave'
                          onDelete={fileValue => onDelete(fileValue)}
                        />,
                      );
                    }
                    return {
                      id: f.id,
                      name: f.name,
                      columns: columnObj,
                    };
                  })}
                  type='popup'
                />
              ) : (
                []
              )}
              <div style={{ marginTop: '15px' }}>
                <Form.TextAreaField
                  {...getFieldProps({
                    id: 'new_time_off',
                    name: 'note',
                  })}
                  height='80px'
                  control={control}
                  defaultValue={existingEvent?.note}
                  placeholder={t('time_off:new_event.placeholders.notes')}
                />
              </div>
            </Modal.Content>
            <ButtonBar>
              {existingEvent && (
                <div className='mr-auto'>
                  <Button color='secondary' onClick={deleteEvent}>
                    Delete Request
                  </Button>
                </div>
              )}
              <div className='mr-2'>
                <Button
                  color='secondary'
                  appearance='outline'
                  onClick={onModalClose}
                >
                  {t('forms:buttons.modal.cancel')}
                </Button>
              </div>
              <Button type='submit' loading={editLoading}>
                {t('forms:buttons.submit')}
              </Button>
            </ButtonBar>
          </Form>
        </Modal>
      </div>
      <ErrorModal
        size='tiny'
        open={!isValidFormat?.isValid}
        header='Error'
        content={isValidFormat?.message}
        actions={['OK']}
        onClose={() => {
          if (existingEvent) {
            setIsValidFormat({ message: null, isValid: true });
            const updateFileList = filesToShow?.filter(file =>
              file?.hasOwnProperty('filename'),
            );
            setEmployeeFiles(updateFileList);
            setFilesToShow(updateFileList);
          } else {
            setIsValidFormat({ message: null, isValid: true }),
              setEmployeeFiles([]),
              setFilesToShow([]);
          }
        }}
      />
    </>
  );
};

NewBooking.propTypes = {
  year: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  contractId: PropTypes.number.isRequired,
  getCalendarData: PropTypes.func.isRequired,
  existingEvent: {
    id: PropTypes.string,
    end_date: PropTypes.string,
    end_perc: PropTypes.number,
    entitlement_used: {},
    event_type: PropTypes.string,
    rejection_note: PropTypes.string,
    request_note: PropTypes.string,
    start_date: PropTypes.string,
    start_perc: PropTypes.number,
    status: PropTypes.string,
  },
  event_types: PropTypes.arrayOf(PropTypes.shape({})),
  holidayYear: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  startDate: PropTypes.shape({
    month: PropTypes.number.isRequired,
    date: PropTypes.number.isRequired,
  }).isRequired,
  endDate: PropTypes.shape({
    month: PropTypes.number.isRequired,
    date: PropTypes.number.isRequired,
  }).isRequired,
  totalLeavesAvailable: PropTypes.any,
  entitlementUsed: PropTypes.any,
  calendarYearsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

NewBooking.defaultProps = {
  isOpen: false,
  onClose: () => {},
  existingEvent: null,
  event_types: [],
  holidayYear: {
    startDate: '',
    endDate: '',
  },
  totalLeavesAvailable: {},
  entitlementUsed: {},
};
export default NewBooking;
